import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DataTableView } from '@x/common/data';
import { IUserPaymentMethodObject } from '@x/ecommerce/domain-client';
import { UserPaymentMethodFilterInput } from '@x/schemas/ecommerce';

@Component({
  selector: 'x-user-payment-method-table',
  templateUrl: './user-payment-method-table.component.html',
  styleUrls: ['./user-payment-method-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-user-payment-method-table',
  },
})
export class UserPaymentMethodTableComponent {
  @Input() view: DataTableView<IUserPaymentMethodObject, UserPaymentMethodFilterInput, any, number>;
}
