import { IDataTableViewOptions } from '@x/common/data';
import { ISubscriberRowObject } from '@x/ecommerce/domain-client';
import { SubscriberFilterInput } from '@x/schemas/ecommerce';

export const SUBSCRIBER_TABLE_OPTIONS: IDataTableViewOptions<
  ISubscriberRowObject,
  SubscriberFilterInput,
  any
> = {
  id: 'subscriber_index',
  columns: [
    { id: 'id', title: 'ID', sortable: true },
    { id: 'user', title: 'User' },
    { id: 'state', title: 'State', sortable: true },
    { id: 'locale', title: 'Locale' },
    { id: 'subscriptionVariant', title: 'Variant' },
    { id: 'shippingAddress', title: 'Shipping Address' },
    { id: 'userPaymentMethod', title: 'Payment Method' },
    { id: 'timestamps', title: 'Timestamps' },
    { id: 'interval', title: 'Interval' },
    { id: 'tags', title: 'Tags' },
  ],
  defaultColumns: [
    'user',
    'state',
    'subscriptionVariant',
    'shippingAddress',
    'userPaymentMethod',
    'timestamps',
    'interval',
    'tags',
  ],
  page: { index: 0, size: 100 },
  pageSizes: [20, 50, 100, 200, 1000],
  sort: {
    column: 'id',
    order: 'desc',
  },
  enableStorage: false,
};
