import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PromptDialogService } from '@x/dashboard/dialog';
import { AddressItemCollectionProvider } from '@x/ecommerce/domain-data';
import {
  UserCreateDialogComponent,
  UserCreateDialogData,
  UserCreateDialogResult,
} from '../components/user-create-dialog/user-create-dialog.component';
import {
  UserRoleAssignmentDialogComponent,
  UserRoleAssignmentDialogData,
  UserRoleAssignmentDialogResult,
} from '../components/user-role-assignment-dialog/user-role-assignment-dialog.component';
import { IGeneratedPassword } from './user-password-generator.service';

@Injectable()
export class UserDialogService {
  constructor(
    private dialogs: MatDialog,
    private prompts: PromptDialogService,
  ) {}

  openUserCreateDialog() {
    return this.dialogs.open<
      UserCreateDialogComponent,
      UserCreateDialogData,
      UserCreateDialogResult
    >(UserCreateDialogComponent);
  }

  openSelectDefaultBillingAddressDialog(userId: number, addressId?: number) {
    return this.prompts.select({
      title: 'Assign Default Billing Address',
      message: 'Select an address to assign as default billing address.',
      cancelLabel: 'Cancel',
      okLabel: 'Assign',
      provider: AddressItemCollectionProvider,
      value: addressId,
      filter: { userIds: [userId] },
    });
  }

  openSelectDefaultShippingAddressDialog(userId: number, addressId?: number) {
    return this.prompts.select({
      title: 'Assign Default Shipping Address',
      message: 'Select an address to assign as default shipping address.',
      cancelLabel: 'Cancel',
      okLabel: 'Assign',
      provider: AddressItemCollectionProvider,
      value: addressId,
      filter: { userIds: [userId] },
    });
  }

  openUserRoleAssignmentDialog(roleIds?: number[]) {
    return this.dialogs.open<
      UserRoleAssignmentDialogComponent,
      UserRoleAssignmentDialogData,
      UserRoleAssignmentDialogResult
    >(UserRoleAssignmentDialogComponent, {
      data: { roleIds },
    });
  }

  openResetPasswordConfirmDialog() {
    return this.prompts.confirm({
      title: 'Reset Password',
      message:
        `Are you sure you want to reset the password for this user?\n` +
        `Only use as a last resort if the customer is not able to log in or reset their password via email.`,
    });
  }

  openResetPasswordDisplayDialog(generated: IGeneratedPassword) {
    return this.prompts.prompt({
      title: `Password Updated`,
      message:
        `The new password is:\n\n${generated.password}\n\n` +
        `Read the phonetics to the customer:\n\n${generated.phoneticPassword}`,
      actions: [{ name: 'Done', result: null, color: 'primary' }],
    });
  }
}
