import { LanguageDeclaration } from '@x/common/locale';

export const USER_LANGUAGE: LanguageDeclaration = {
  user: {
    prompt: {
      update: {
        title: 'Success',
        message: 'User has been successfully updated.',
        okay: 'Okay',
      },
      noUpdate: {
        title: 'Nothing to update',
        okay: 'Okay',
      },
    },
  },
};
