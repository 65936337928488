import { Component, Input } from '@angular/core';
import { DataTableView } from '@x/common/data';
import { IAgreementDeclarationItemObject } from '@x/ecommerce/domain-client';
import { AgreementDeclarationFilter } from '@x/schemas/ecommerce';

@Component({
  selector: 'x-agreement-table',
  templateUrl: './agreement-table.component.html',
  styleUrls: ['./agreement-table.component.scss'],
  host: {
    class: 'x-agreement-table',
  },
})
export class AgreementTableComponent {
  @Input() view: DataTableView<
    IAgreementDeclarationItemObject,
    AgreementDeclarationFilter,
    any,
    number
  >;
  constructor() {}
}
