<x-data-table [view]="view">
  <ng-template xDataColumnDef="id" let-row>
    {{ row.id }}
  </ng-template>
  <ng-template xDataColumnDef="fullName" let-row>
    {{ row.firstName }} {{ row.lastName }}
  </ng-template>
  <ng-template xDataColumnDef="email" let-row>
    {{ row.email }}
  </ng-template>

  <ng-template xDataColumnDef="phone" let-row>
    {{ row.phone }}
  </ng-template>

  <ng-template xDataColumnDef="defaultShippingAddress" let-row>
    <x-address-pill [address]="row.defaultShippingAddress"></x-address-pill>
  </ng-template>

  <ng-template xDataColumnDef="defaultBillingAddress" let-row>
    <x-address-pill [address]="row.defaultBillingAddress"></x-address-pill>
  </ng-template>

  <ng-template xDataColumnDef="defaultChannel" let-row>
    {{ row.defaultChannel?.name ?? '-' }}
  </ng-template>

  <ng-template xDataColumnDef="defaultLocale" let-row>
    {{ row.defaultLocale ?? '-' }}
  </ng-template>

  <ng-template xDataColumnDef="roles" let-row>
    <x-pill *ngFor="let role of row.roles">
      <div class="mat-body">
        {{ role.name }}
      </div>
    </x-pill>
  </ng-template>

  <ng-template xDataColumnDef="memberships" let-row>
    <span *ngFor="let member of row.members">
      <x-member-pill [member]="member"></x-member-pill>
    </span>
  </ng-template>

  <ng-template xDataColumnDef="subscriptions" let-row>
    <x-pill *ngFor="let subscription of row.subscribers">
      {{ subscription.variant.name }}
    </x-pill>
  </ng-template>

  <a
    x-data-button
    *xDataAction="'detail'; let row; primary: true"
    [routerLink]="['/admin/users', 'detail', row.id]"
    label="View User"
    icon="search"
  ></a>

  <!-- <button
    x-data-button
    *xDataAction="'archiveUser'; let row"
    label="Archive User"
    icon="trash"
    (click)="archiveUser(row.id)"
  ></button> -->

  <button
    x-data-button
    *xDataAction="'selectDefaultBillingAddress'; let row"
    label="Select Default Billing Address"
    icon="map-marked-alt"
    (click)="selectDefaultBillingAddress(row.id, row.defaultBillingAddress?.id)"
  ></button>

  <button
    x-data-button
    *xDataAction="'selectDefaultShippingAddress'; let row"
    label="Select Default Shipping Address"
    icon="map-marked-alt"
    (click)="selectDefaultShippingAddress(row.id, row.defaultShippingAddress?.id)"
  ></button>

  <button
    x-data-button
    *xDataAction="'assignRoles'; let row"
    label="Assign Auth Roles"
    icon="user"
    (click)="assignRole(row.id, row.roles)"
  ></button>

  <button
    x-data-button
    *xDataBulkAction="'user'; let ids"
    label="Assign Auth Roles"
    icon="user-shield"
    color="primary"
    (click)="assignRoleBulk()"
  ></button>

  <button
    x-data-button
    *xDataAction="'sendResetPasswordEmail'; let row"
    label="Send Password Reset Email"
    icon="envelope"
    (click)="sendResetPasswordEmail(row.id, row.email)"
  ></button>
  <button
    x-data-button
    *xDataAction="'resetPassword'; let row"
    label="Reset Password"
    icon="refresh"
    (click)="resetUserPassword(row.id)"
  ></button>

  <button
    x-data-button
    *xDataBulkAction="'membership'; let ids"
    label="Membership"
    icon="id-card"
    color="primary"
    [matMenuTriggerFor]="membershipMenu"
  ></button>
</x-data-table>

<mat-menu #membershipMenu>
  <ng-template matMenuContent let-taxon>
    <button mat-menu-item (click)="activateMembershipsBulk()">Activate</button>
    <button mat-menu-item (click)="deactivateMemberships()">Deactivate</button>
  </ng-template>
</mat-menu>
