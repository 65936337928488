import { IDataTableViewOptions } from '@x/common/data';
import { IAgreementDeclarationItemObject } from '@x/ecommerce/domain-client';
import { AgreementDeclarationFilter } from '@x/schemas/ecommerce';

export const AGREEMENT_DECLARATION_TABLE_OPTIONS_VIEW: IDataTableViewOptions<
  IAgreementDeclarationItemObject,
  AgreementDeclarationFilter,
  any
> = {
  id: 'agreement_table',
  columns: [
    { id: 'id', title: 'ID', sortable: true },
    { id: 'title', title: 'Title' },
    { id: 'agreed', title: 'Agreed' },
    { id: 'agreedAt', title: 'Agreed At' },
  ],
  defaultColumns: ['id', 'title', 'agreed', 'agreedAt'],
  page: { index: 0, size: 100 },
  pageSizes: [20, 50, 100, 200, 1000],
  sort: {
    column: 'id',
    order: 'desc',
  },
  enableStorage: false,
};
