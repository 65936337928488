<x-data-table [view]="view">
  <div *xDataColumnDef="'id'; let row">{{ row.id }}</div>
  <div *xDataColumnDef="'method'; let row">{{ row.method.id }}</div>
  <div *xDataColumnDef="'description'; let row">{{ row.description }}</div>
  <div *xDataColumnDef="'state'; let row">
    <x-pill [color]="row.state === 'ACTIVE' ? 'primary' : 'default'">
      {{ row.state }}
    </x-pill>
  </div>
  <div *xDataColumnDef="'createdAt'; let row">{{ row.createdAt | datetime: 'short' }}</div>
  <div *xDataColumnDef="'updatedAt'; let row">{{ row.updatedAt | datetime: 'short' }}</div>
  <div *xDataColumnDef="'expiresAt'; let row">{{ row.expiresAt | datetime: 'shortDate' }}</div>
  <div *xDataColumnDef="'subscriptions'; let row" class="mat-body-2">
    <x-pill *ngFor="let subscription of row.subscribers">
      {{ subscription.variant.name }}
    </x-pill>
  </div>
</x-data-table>
