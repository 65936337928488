import type { IDataTableViewOptions } from '@x/common/data';
import { IAddressObject } from '@x/ecommerce/domain-client';
import { AddressFilterInput } from '@x/schemas/ecommerce';

export const ADDRESS_TABLE_VIEW_OPTIONS: IDataTableViewOptions<
  IAddressObject,
  AddressFilterInput,
  any
> = {
  id: 'address_index',
  columns: [
    { id: 'id', title: 'ID', sortable: true },
    {
      id: 'alias',
      title: 'Alias',
    },
    {
      id: 'street',
      title: 'Street',
    },
    {
      id: 'city',
      title: 'City',
    },
    {
      id: 'province',
      title: 'Province',
    },
    {
      id: 'country',
      title: 'Country',
    },
    {
      id: 'postalCode',
      title: 'Postal Code',
    },
  ],
  defaultColumns: ['id', 'alias', 'street', 'city', 'province', 'country', 'postalCode'],
  page: { index: 0, size: 50 },
  pageSizes: [20, 50, 100, 200, 500],
};
