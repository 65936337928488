import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DataTableView } from '@x/common/data';
import { PromptDialogService } from '@x/dashboard/dialog';
import { MemberDialogService } from '@x/ecommerce-admin/app/memberships/services/member-dialog.service';
import {
  AuthService,
  IAuthRoleDetailObject,
  IUserRowObject,
  MemberService,
  UserService,
} from '@x/ecommerce/domain-client';
import { MembershipItemCollectionProvider } from '@x/ecommerce/domain-data';
import { UserFilterInput } from '@x/schemas/ecommerce';
import { firstValueFrom, lastValueFrom, of } from 'rxjs';
import { UserDialogService } from '../../services/user-dialog.service';
import { UserPasswordGeneratorService } from '../../services/user-password-generator.service';
@Component({
  selector: 'x-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class UserTableComponent {
  @Input()
  view: DataTableView<IUserRowObject, UserFilterInput, any, number>;

  constructor(
    private userService: UserService,
    private userDialogService: UserDialogService,
    private prompts: PromptDialogService,
    private authService: AuthService,
    private userPasswordGenerator: UserPasswordGeneratorService,
    private memberService: MemberService,
    private memberDialogService: MemberDialogService,
  ) {}

  selectDefaultBillingAddress(userId: number, currentId?: number) {
    this.userDialogService
      .openSelectDefaultBillingAddressDialog(userId, currentId)
      .afterClosed()
      .subscribe((result) => {
        if (result?.assign) {
          this.view
            .mutateRow(userId, (userId) =>
              this.userService.updateAddress({
                userId,
                addressId: result.value,
                setAsDefaultBillingAddress: true,
              }),
            )
            .subscribe();
        }
      });
  }

  selectDefaultShippingAddress(userId: number, currentId?: number) {
    this.userDialogService
      .openSelectDefaultShippingAddressDialog(userId, currentId)
      .afterClosed()
      .subscribe((result) => {
        if (result?.assign) {
          this.view
            .mutateRow(userId, (userId) =>
              this.userService.updateAddress({
                userId,
                addressId: result.value,
                setAsDefaultShippingAddress: true,
              }),
            )
            .subscribe();
        }
      });
  }

  assignRole(userId: number, currentRoles?: IAuthRoleDetailObject[]) {
    this.userDialogService
      .openUserRoleAssignmentDialog(currentRoles?.map((role) => role.id))
      .afterClosed()
      .subscribe((result) => {
        this.view
          .mutateRow(userId, (userId) =>
            this.userService.updateRoles({
              id: userId,
              roleIds: result?.data.roles ?? [],
            }),
          )
          .subscribe();
      });
  }

  assignRoleBulk() {
    this.userDialogService
      .openUserRoleAssignmentDialog()
      .afterClosed()
      .subscribe((result) => {
        this.view
          .mutateEachSelected((userId) =>
            this.userService.updateRoles({
              id: userId,
              roleIds: result?.data.roles ?? [],
            }),
          )
          .subscribe();
      });
  }

  archiveUser(userId: number) {
    this.prompts
      .confirm({
        title: 'Archive User',
        message: 'Are you sure you want to archive this user?',
      })
      .subscribe((result) => {
        if (result) {
          this.view.mutateRow(userId, (userId) => this.userService.archive(userId)).subscribe();
        }
      });
  }

  sendResetPasswordEmail(userId: number, email: string) {
    this.prompts
      .confirm({
        title: 'Send Password Reset Email',
        message: 'Are you sure you want to send a password reset email for this user?',
      })
      .subscribe((result) => {
        if (result) {
          this.view.mutateRow(userId, () => this.authService.forgotPassword({ email })).subscribe();
        }
      });
  }

  async resetUserPassword(userId: number) {
    if (!(await lastValueFrom(this.userDialogService.openResetPasswordConfirmDialog()))) {
      return;
    }

    const generated = this.userPasswordGenerator.generateNewPassword();

    this.view
      .mutateRow(userId, (id) =>
        this.userService.resetUserPassword({ userId: id, newPassword: generated.password }),
      )
      .subscribe((r) => {
        if (r.state.status === 'ok') {
          this.userDialogService.openResetPasswordDisplayDialog(generated);
        }
      });
  }

  async activateMembershipsBulk() {
    const result = await firstValueFrom(
      this.memberDialogService
        .membershipBulkUpdateDialog({
          provider: MembershipItemCollectionProvider,
          multiple: false,
          title: 'Activate Membership',
          message: 'Select membership to activate for selected users.',
        })
        .afterClosed(),
    );

    if (!result || !result.assign) return;

    this.view
      .mutateEachSelectedBatched(
        (userId) => {
          if (!result.value || !result.channelId || typeof result.value !== 'number')
            return of(null);

          return this.memberService.activateUserMembership({
            userId,
            membershipId: result.value,
            channelId: result.channelId,
          });
        },
        { label: 'Activating Memberships' },
      )
      .subscribe();
  }

  async deactivateMemberships() {
    const result = await firstValueFrom(
      this.memberDialogService
        .membershipBulkUpdateDialog({
          provider: MembershipItemCollectionProvider,
          multiple: false,
          title: 'Deactivate Membership',
          message: 'Select membership to deactivate for selected users.',
        })
        .afterClosed(),
    );

    if (!result || !result.assign) return;

    this.view
      .mutateEachSelectedBatched(
        (userId) => {
          if (!result.value || !result.channelId || typeof result.value !== 'number')
            return of(null);

          return this.memberService.deactivateUserMembership({
            userId,
            membershipId: result.value,
            channelId: result.channelId,
          });
        },
        { label: 'Deactivating Memberships' },
      )
      .subscribe();
  }
}
