import { Injectable } from '@angular/core';

export interface IGeneratedPassword {
  password: string;
  phoneticPassword: string;
}

@Injectable({ providedIn: 'root' })
export class UserPasswordGeneratorService {
  readonly phoneticAlphabet: { [key: string]: string } = {
    a: 'Alpha',
    b: 'Bravo',
    c: 'Charlie',
    d: 'Delta',
    e: 'Echo',
    f: 'Foxtrot',
    g: 'Golf',
    h: 'Hotel',
    i: 'India',
    j: 'Juliett',
    k: 'Kilo',
    l: 'Lima',
    m: 'Mike',
    n: 'November',
    o: 'Oscar',
    p: 'Papa',
    q: 'Quebec',
    r: 'Romeo',
    s: 'Sierra',
    t: 'Tango',
    u: 'Uniform',
    v: 'Victor',
    w: 'Whiskey',
    x: 'XRay',
    y: 'Yankee',
    z: 'Zulu',
  };

  generateNewPassword(): IGeneratedPassword {
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';

    let password = '';

    for (let i = 0; i < 4; i++) {
      const randomLetterIndex = Math.floor(Math.random() * letters.length);
      password += letters[randomLetterIndex];
    }
    for (let i = 0; i < 4; i++) {
      const randomNumberIndex = Math.floor(Math.random() * numbers.length);
      password += numbers[randomNumberIndex];
    }

    const phoneticPassword = password
      .split('')
      .map((char) => this.phoneticAlphabet[char] ?? char)
      .join('-');

    return { password, phoneticPassword };
  }
}
