import { IDataTableViewOptions } from '@x/common/data';

export const USER_PAYMENT_METHOD_TABLE_VIEW_OPTIONS: IDataTableViewOptions = {
  id: 'payment_method_index',
  columns: [
    { id: 'id', title: 'ID', sortable: true },
    { id: 'method', title: 'Method ID' },
    { id: 'description', title: 'Description' },
    { id: 'state', title: 'State' },
    { id: 'createdAt', title: 'Created At' },
    { id: 'updatedAt', title: 'Updated At' },
    { id: 'expiresAt', title: 'Expires At' },
    { id: 'subscriptions', title: 'Subscriptions' },
  ],
  defaultColumns: ['id', 'method', 'description', 'state', 'createdAt', 'updatedAt', 'expiresAt'],
  page: { index: 0, size: 50 },
  pageSizes: [20, 50, 100, 200, 500],
};
