import { NgModule } from '@angular/core';
import { DashboardCoreModule } from '@x/dashboard/core';
import { DashboardGridModule } from '@x/dashboard/grid';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { AgreementTableComponent } from './components/agreement-table/agreement-table.component';

@NgModule({
  declarations: [AgreementTableComponent],
  exports: [AgreementTableComponent],
  imports: [DashboardCoreModule, DashboardGridModule, EcommerceAdminCoreModule],
})
export class AgreementSharedModule {}
