import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { PromptDialogService } from '@x/dashboard/dialog';
import { IUserDetailObject } from '@x/ecommerce/domain-client';
import { UpdateUserInput } from '@x/schemas/ecommerce';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { UserFetch, UserUpdate } from './user.actions';
import { UserStateModel } from './user.state';

@Component({
  selector: 'x-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  private _destroy$ = new Subject<void>();
  userId: number = Number(this.route.snapshot.params.userId);

  @Select((state: { userState: UserStateModel }) => state.userState.loading)
  loading$: Observable<boolean>;

  @Select((state: { userState: UserStateModel }) => state.userState.user)
  user$: Observable<IUserDetailObject>;

  // userForm
  formGroup = new UntypedFormGroup({
    id: new UntypedFormControl(),
    firstName: new UntypedFormControl(),
    lastName: new UntypedFormControl(),
    email: new UntypedFormControl(null, [Validators.email, Validators.required]),
    phone: new UntypedFormControl(),
    birthday: new UntypedFormControl(),
    gender: new UntypedFormControl(),
    defaultChannelId: new UntypedFormControl(),
    defaultLocale: new UntypedFormControl(),
  });

  get channelId() {
    return this.formGroup.get('defaultChannelId')?.value;
  }
  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private promptDialogs: PromptDialogService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new UserFetch(this.userId));

    // patch the form when user updates
    this.user$
      .pipe(
        takeUntil(this._destroy$),
        tap((user) => {
          if (user) {
            // patch user form
            this.formGroup.patchValue(
              { ...user, ...(user.defaultChannel && { defaultChannelId: user.defaultChannel.id }) },
              { emitEvent: false },
            );
          }
        }),
      )
      .subscribe();
  }

  submit() {
    this.formGroup.updateValueAndValidity();
    const { value, touched, invalid } = this.formGroup;

    if (!touched) {
      this.promptDialogs.confirm({
        title: 'user.prompt.noUpdate.title',
        actions: [
          {
            name: 'user.prompt.noUpdate.okay',
            result: true,
          },
        ],
      });
      return;
    }

    if (invalid) return;

    this.update(value);
  }

  isDirty(): boolean {
    this.formGroup.updateValueAndValidity();
    return this.formGroup.dirty;
  }

  async update(updateUserInput: UpdateUserInput) {
    await this.store.dispatch(new UserUpdate(updateUserInput)).toPromise();
    await this.promptDialogs
      .confirm({
        title: 'user.prompt.update.title',
        message: 'user.prompt.update.message',
        actions: [
          {
            name: 'user.prompt.update.okay',
            result: true,
          },
        ],
      })
      .toPromise();
    this.back();
  }

  back() {
    this.formGroup.reset();
    this.location.back();
  }
}
