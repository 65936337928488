<h2 mat-dialog-title>Create User</h2>
<form
  mat-dialog-content
  [formGroup]="userCreateForm"
  id="user_create_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>First name</mat-label>
        <input
          matInput
          type="text"
          formControlName="firstName"
          required
          autocomplete="disabled"
          maxlength="60"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Last name</mat-label>
        <input
          matInput
          type="text"
          formControlName="lastName"
          required
          autocomplete="disabled"
          maxlength="60"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input
          matInput
          type="email"
          formControlName="email"
          required
          autocomplete="disabled"
          maxlength="60"
        />
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button mat-button type="submit" color="primary" form="user_create_form">Create</button>
</div>
