import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthRoleItemCollectionProvider } from '@x/ecommerce/domain-data';
import { Subject } from 'rxjs';

export interface UserRoleAssignmentDialogData {
  roleIds?: number[];
}

export interface UserRoleAssignmentDialogResult {
  data: { roles?: number[] };
}

@Component({
  selector: 'x-user-role-assignment-dialog',
  templateUrl: './user-role-assignment-dialog.component.html',
  styleUrls: ['./user-role-assignment-dialog.component.scss'],
})
export class UserRoleAssignmentDialogComponent implements OnInit, OnDestroy {
  readonly Providers = {
    AuthRoleItemCollectionProvider,
  };

  private _destroy$ = new Subject<void>();

  roleAssignmentForm = new FormGroup({
    roles: new FormControl([] as number[]),
  });

  constructor(
    public dialog: MatDialogRef<UserRoleAssignmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: UserRoleAssignmentDialogData,
  ) {}

  ngOnInit(): void {
    if (this.data?.roleIds) {
      this.roleAssignmentForm.setValue({
        roles: this.data.roleIds,
      });
      return;
    }
  }

  save() {
    this.roleAssignmentForm.updateValueAndValidity();
    if (this.roleAssignmentForm.invalid) return;

    this.dialog.close({ data: { roles: this.roleAssignmentForm.get('roles')?.value ?? [] } });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
