<h2 mat-dialog-title>Assign User Roles</h2>
<x-dashboard-form-layout [formGroup]="roleAssignmentForm">
  <x-form-panel>
    <mat-form-field class="flex-grow-0">
      <mat-label>
        <fa-icon icon="search" matPrefix></fa-icon>
        Search Roles
      </mat-label>
      <x-data-autocomplete
        formControlName="roles"
        [provider]="Providers.AuthRoleItemCollectionProvider"
        [multiple]="true"
      ></x-data-autocomplete>
    </mat-form-field>
  </x-form-panel>
</x-dashboard-form-layout>

<div mat-dialog-actions align="end">
  <button mat-button (click)="dialog.close()">Cancel</button>
  <button mat-button color="primary" (click)="save()">Save</button>
</div>
