import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@x/ecommerce/domain-client';
import { CreateUserInput } from '@x/schemas/ecommerce';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface UserCreateDialogData {}

export interface UserCreateDialogResult {
  data?: { id: number };
}

@Component({
  selector: 'x-user-create-dialog',
  templateUrl: './user-create-dialog.component.html',
  styleUrls: ['./user-create-dialog.component.scss'],
})
export class UserCreateDialogComponent implements OnInit {
  //unsubscribe
  private _destroy$ = new Subject<void>();

  userCreateForm = new UntypedFormGroup({
    firstName: new UntypedFormControl('', Validators.required),
    lastName: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    public dialog: MatDialogRef<UserCreateDialogComponent>,
    private userService: UserService,
  ) {}

  ngOnInit(): void {}

  submit() {
    this.userCreateForm.updateValueAndValidity();

    if (this.userCreateForm.invalid) return;

    const input: CreateUserInput = this.userCreateForm.value;

    this.userService
      .create(input)
      .pipe(
        takeUntil(this._destroy$),
        tap((data) => this.dialog.close({ data })),
      )
      .subscribe();
  }
}
