<form
  id="user_update_form"
  class="user_update_form"
  novalidate
  autocomplete="off"
  [formGroup]="formGroup"
  (ngSubmit)="submit()"
  *ngIf="user$ | async as user"
>
  <x-dashboard-layout-simplex>
    <x-dashboard-toolbar icon="users" header="User" subheader="Information">
      <button xToolbarAction type="button" mat-button (click)="back()">
        <fa-icon icon="times"></fa-icon>
        Cancel
      </button>
      <button
        xToolbarAction
        mat-flat-button
        color="primary"
        type="button"
        (click)="submit()"
        form="user_update_form"
      >
        <fa-icon icon="check"></fa-icon>
        Update
      </button>
    </x-dashboard-toolbar>
    <x-dashboard-panel [panelLoading]="loading$ | async">
      <x-dashboard-form-layout [cols]="2">
        <x-form-panel>
          <mat-form-field>
            <mat-label>First name</mat-label>
            <textarea matInput cdkTextareaAutosize formControlName="firstName"></textarea>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Email</mat-label>
            <textarea matInput cdkTextareaAutosize formControlName="email"></textarea>
          </mat-form-field>
          <x-channel-select
            formControlName="defaultChannelId"
            label="Default channel"
          ></x-channel-select>
          <mat-form-field>
            <mat-label>Gender</mat-label>
            <mat-select formControlName="gender">
              <mat-option value="u">Unknown</mat-option>
              <mat-option value="m">Male</mat-option>
              <mat-option value="f">Female</mat-option>
            </mat-select>
          </mat-form-field>
        </x-form-panel>
        <x-form-panel>
          <mat-form-field>
            <mat-label>Last name</mat-label>
            <textarea matInput cdkTextareaAutosize formControlName="lastName"></textarea>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Phone</mat-label>
            <textarea matInput cdkTextareaAutosize formControlName="phone"></textarea>
          </mat-form-field>
          <x-locale-select
            formControlName="defaultLocale"
            [byChannelId]="channelId"
          ></x-locale-select>
          <mat-form-field appearance="fill">
            <mat-label>Birthday</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="birthday" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </x-form-panel>
      </x-dashboard-form-layout>
    </x-dashboard-panel>
  </x-dashboard-layout-simplex>
</form>
