import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DataDisplayControl, DataFilterControl, DataViewFactoryService } from '@x/common/data';
import { PromptDialogService } from '@x/dashboard/dialog';
import { AGREEMENT_DECLARATION_TABLE_OPTIONS_VIEW } from '@x/ecommerce-admin/app/agreement/components/agreement-table/agreement-table-options';
import { ADDRESS_TABLE_VIEW_OPTIONS } from '@x/ecommerce-admin/app/core/components/address-table/address-table-options';
import { SUBSCRIBER_TABLE_OPTIONS } from '@x/ecommerce-admin/app/core/components/subscriber-table/subscriber-table-options';
import { AddressDialogService } from '@x/ecommerce-admin/app/core/services/address-dialog.service';
import { MEMBER_TABLE_OPTIONS_VIEW } from '@x/ecommerce-admin/app/memberships/components/member-table/member-table-options';
import { ORDER_TABLE_VIEW_OPTIONS } from '@x/ecommerce-admin/app/orders/components/order-table/order-table-options';
import { USER_PAYMENT_METHOD_TABLE_VIEW_OPTIONS } from '@x/ecommerce-admin/app/users/components/user-payment-method-table/user-payment-method-table-options';
import { VOUCHER_TABLE_VIEW_OPTIONS } from '@x/ecommerce-admin/app/voucher/components/voucher-table/voucher-table-options';
import { WALLET_TABLE_VIEW_OPTIONS } from '@x/ecommerce-admin/app/wallets/components/wallet-table/wallet-table-options';
import {
  IUserDetailObject,
  ProductWishlistItemRowObject,
  ProductWishlistItemService,
  UserService,
} from '@x/ecommerce/domain-client';
import {
  AddressItemCollectionProvider,
  AgreementDeclarationCollectionProvider,
  MemberRowCollectionProvider,
  OrderDetailProvider,
  OrderRowCollectionProvider,
  ProductItemCollectionProvider,
  ProductWishlistItemRowCollectionProvider,
  SubscriberRowCollectionProvider,
  UserAddressIndexDatasource,
  UserDetailProvider,
  UserPaymentMethodRowCollectionProvider,
  VoucherRowCollectionProvider,
  WalletRowCollectionProvider,
} from '@x/ecommerce/domain-data';
import { OrderState } from '@x/schemas/ecommerce';
import { Subject, firstValueFrom, lastValueFrom, startWith, switchMap, takeUntil } from 'rxjs';
import { PRODUCT_WISHLIST_ITEMS_TABLE_VIEW_OPTIONS } from '../../../products/components/product-wishlist-items-table/product-wishlist-items-table-options';
import { UserDialogService } from '../../services/user-dialog.service';
import { UserPasswordGeneratorService } from '../../services/user-password-generator.service';

export interface UserDetailState {
  data?: IUserDetailObject;
  loading: boolean;
  error?: any;
}

@Component({
  selector: 'x-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailComponent implements OnInit, OnDestroy {
  userView = this.dataViewFactory.resolveView(UserDetailProvider, {
    id: Number(this.route.snapshot.paramMap.get('userId')),
  });

  orderStateControl = new FormControl([
    OrderState.Processing,
    OrderState.Confirmed,
    OrderState.Completed,
  ]);

  orderFilter = new DataFilterControl({
    states: this.orderStateControl,
  });

  orderView = this.dataViewFactory.resolveTableView(OrderRowCollectionProvider, {
    ...ORDER_TABLE_VIEW_OPTIONS,
    id: 'user_detail_orders',
    enableStorage: true,
    filterFixture: () => {
      return { userIds: [Number(this.route.snapshot.paramMap.get('userId'))] };
    },
    filterControl: this.orderFilter,
    defaultColumns: ['number', 'shippingAddress', 'fulfilment', 'payment'],
    displayOptions: {
      showQuickView: false,
    },
  });

  readonly detailView = this.dataViewFactory.resolveView(OrderDetailProvider);
  showQuickViewControl = new FormControl(false);
  displayGroup = new DataDisplayControl(this.orderView, {
    showQuickView: this.showQuickViewControl,
  });

  agreementView = this.dataViewFactory.resolveTableView(AgreementDeclarationCollectionProvider, {
    ...AGREEMENT_DECLARATION_TABLE_OPTIONS_VIEW,
    id: 'user_detail_agreements',
    enableStorage: true,
    filterFixture: () => {
      return { userIds: [Number(this.route.snapshot.paramMap.get('userId'))] };
    },
  });

  addressView = this.dataViewFactory.resolveTableView(AddressItemCollectionProvider, {
    ...ADDRESS_TABLE_VIEW_OPTIONS,
    id: 'user_detail_addresses',
    enableStorage: true,
    filterFixture: () => {
      return { userIds: [Number(this.route.snapshot.paramMap.get('userId'))] };
    },
  });

  walletView = this.dataViewFactory.resolveTableView(WalletRowCollectionProvider, {
    ...WALLET_TABLE_VIEW_OPTIONS,
    id: 'user_detail_wallets',
    enableStorage: true,
    filterFixture: () => {
      return { userIds: [Number(this.route.snapshot.paramMap.get('userId'))] };
    },
  });

  paymentMethodView = this.dataViewFactory.resolveTableView(
    UserPaymentMethodRowCollectionProvider,
    {
      ...USER_PAYMENT_METHOD_TABLE_VIEW_OPTIONS,
      id: 'user_detail_payment_methods',
      enableStorage: true,
      filterFixture: () => {
        return { userIds: [Number(this.route.snapshot.paramMap.get('userId'))] };
      },
      defaultColumns: ['id', 'description', 'state', 'createdAt', 'updatedAt', 'expiresAt'],
    },
  );

  subscriberView = this.dataViewFactory.resolveTableView(SubscriberRowCollectionProvider, {
    ...SUBSCRIBER_TABLE_OPTIONS,
    id: 'user_detail_subscriptions',
    enableStorage: true,
    filterFixture: () => {
      return { userIds: [Number(this.route.snapshot.paramMap.get('userId'))] };
    },
  });

  memberView = this.dataViewFactory.resolveTableView(MemberRowCollectionProvider, {
    ...MEMBER_TABLE_OPTIONS_VIEW,
    id: 'user_detail_memberships',
    enableStorage: true,
    filterFixture: () => {
      return { userIds: [Number(this.route.snapshot.paramMap.get('userId'))] };
    },
  });

  voucherView = this.dataViewFactory.resolveTableView(VoucherRowCollectionProvider, {
    ...VOUCHER_TABLE_VIEW_OPTIONS,
    id: 'user_detail_vouchers',
    enableStorage: true,
    filterFixture: () => {
      return { userIds: [Number(this.route.snapshot.paramMap.get('userId'))] };
    },
    defaultColumns: [
      'code',
      'value',
      'currency',
      'orderNumber',
      'reason',
      'issuingUser',
      'cancelled',
      'createdAt',
    ],
  });

  wishlistItemsView = this.dataViewFactory.resolveTableView(
    ProductWishlistItemRowCollectionProvider,
    {
      ...PRODUCT_WISHLIST_ITEMS_TABLE_VIEW_OPTIONS,
      id: 'user_detail_wishlist_items',
      filterFixture: () => {
        return { userId: Number(this.route.snapshot.paramMap.get('userId')) };
      },
    },
  );

  private readonly _destroy$ = new Subject();

  constructor(
    public readonly datasource: UserAddressIndexDatasource,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly _snackBar: MatSnackBar,
    private readonly changeRef: ChangeDetectorRef,
    private readonly dataViewFactory: DataViewFactoryService,
    private readonly prompts: PromptDialogService,
    private readonly userPasswordGenerator: UserPasswordGeneratorService,
    private readonly userDialogService: UserDialogService,
    private readonly addressDialogService: AddressDialogService,
    private readonly userService: UserService,
    private readonly productWishlistItemService: ProductWishlistItemService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.userView.connect();
    this.userView.stateChanges().subscribe(() => this.changeRef.markForCheck());

    this.orderView
      .activeChanges()
      .pipe(startWith(this.orderView.getActive()), takeUntil(this._destroy$))
      .subscribe((c) => {
        this.detailView.setId(this.orderView.getActive());
      });

    this.detailView.connect();
    this.detailView.stateChanges().subscribe(() => this.changeRef.markForCheck());
  }

  ngOnDestroy(): void {
    this.userView.disconnect();
  }

  editUser(id: number) {
    this.router.navigateByUrl(`/admin/users/form/${id}`);
  }

  async editRoles(id: number) {
    this.userService
      .fetchRoles(id)
      .pipe(
        takeUntil(this._destroy$),
        switchMap((roles) =>
          this.userView.observeMutation((id) =>
            this.userDialogService
              .openUserRoleAssignmentDialog(roles.map((r) => r.id))
              .afterClosed()
              .pipe(
                takeUntil(this._destroy$),
                switchMap((result) => {
                  if (result && result.data.roles) {
                    return this.userService.updateRoles({ id, roleIds: result.data.roles });
                  }
                  return [];
                }),
              ),
          ),
        ),
      )
      .subscribe();
  }

  async createAddress(userId: number) {
    this.addressDialogService
      .openAddressInputDialog({
        title: 'Create user address',
        okLabel: 'Save',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.value) {
          this.addressView
            .mutate(() => {
              return this.userService.createAddress({
                userId,
                address: result.value,

                // TODO: consider these:
                // setAsDefaultBillingAddress,
                // setAsDefaultShippingAddress,
              });
            })
            .subscribe();
        }
      });
  }

  sendResetPasswordEmail() {
    this.prompts
      .confirm({
        title: 'Send Password Reset Email',
        message: 'Are you sure you want to send a password reset email for this user?',
      })
      .subscribe((result) => {
        if (result) {
          this.userView
            .observeMutation((id) => this.userService.sendPasswordResetEmail(id))
            .subscribe((r) => {
              if (r.state.status === 'ok') {
                this._snackBar.open(
                  `A password reset email was sent to ${r.state.data?.recipientEmail}, containing a instructions to reset the password.`,
                );
              }
            });
        }
      });
  }

  async resetUserPassword() {
    if (!(await lastValueFrom(this.userDialogService.openResetPasswordConfirmDialog()))) {
      return;
    }

    const generated = this.userPasswordGenerator.generateNewPassword();

    this.userView
      .observeMutation((id) =>
        this.userService.resetUserPassword({ userId: id, newPassword: generated.password }),
      )
      .subscribe((r) => {
        if (r.state.status === 'ok') {
          this.userDialogService.openResetPasswordDisplayDialog(generated);
        }
      });
  }

  async addWishlistItems() {
    const result = await firstValueFrom(
      this.prompts
        .autocomplete({
          title: 'Select items to add',
          provider: ProductItemCollectionProvider,
          multiple: true,
        })
        .afterClosed(),
    );
    if (result?.assign && result.value) {
      const userId = Number(this.route.snapshot.paramMap.get('userId'));
      const productIds = result.value as unknown as number[];
      const items = await Promise.all(
        productIds.map(async (productId: number) =>
          firstValueFrom(
            this.productWishlistItemService.addItem({
              userId,
              productId,
            }),
          ),
        ),
      );

      this.wishlistItemsView.refresh();
    }
  }

  async removeWishlistItem(item: ProductWishlistItemRowObject) {
    const result = await firstValueFrom(
      this.prompts.confirm({
        title: 'Remove Item',
        message: `Are you sure you want to remove ${item.product.name} from the list?`,
      }),
    );

    if (result) {
      const userId = Number(this.route.snapshot.paramMap.get('userId'));
      const productId = Number(item.product.id);
      await firstValueFrom(
        this.productWishlistItemService.removeItem({
          userId,
          productId,
        }),
      );

      this._snackBar.open('Item removed');
      this.wishlistItemsView.refresh();
    }
  }
}
