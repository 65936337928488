import { UpdateUserInput } from '@x/schemas/ecommerce';

export class UserFetch {
  static readonly type = '[User] Fetch';
  constructor(public userId: number) {}
}

export class UserUpdate {
  static readonly type = '[User] Update';
  constructor(public updateUserInput: UpdateUserInput) {}
}
