import { IDataTableViewOptions } from '@x/common/data';

export const PRODUCT_WISHLIST_ITEMS_TABLE_VIEW_OPTIONS: IDataTableViewOptions = {
  id: 'product_wishlist_items_index',
  columns: [
    {
      id: 'id',
      title: 'ID',
      sortable: true,
    },
    {
      id: 'user',
      title: 'User',
      sortable: true,
    },
    {
      id: 'product',
      title: 'Product',
      sortable: true,
    },
    {
      id: 'timestamps',
      title: 'Timestamps',
    },
  ],
  sorts: [
    {
      id: 'updatedAt',
      title: 'Updated At',
    },
    {
      id: 'createdAt',
      title: 'Created At',
    },
  ],
  defaultColumns: ['product', 'timestamps'],
  page: { index: 0, size: 50 },
  pageSizes: [20, 50, 100, 200, 500],
  sort: {
    column: 'updatedAt',
    order: 'desc',
  },
};
